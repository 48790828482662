body {
  font-family: "Roboto", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  width: 100%;
  min-height: 100vh;
  color: #1d1d1d;
  margin: 0;
  padding: 0;
  background-color: rgb(215, 218, 253);
}
