@import "animate.css";
@keyframes bubblePop {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.home_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 12vmax);
  max-width: 820px;
  min-height: fit-content;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
  .title {
    font-size: calc(1rem + 3vmin);
    font-family: "Merienda", "Roboto", system-ui, -apple-system, "Open Sans",
      "Helvetica Neue", sans-serif;
  }
  form {
    border-radius: 20px;
    width: fit-content;
    padding: 20px 30px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(252, 234, 220, 0.993);
    @media screen and (max-width: 500px) {
      padding: 10px 20px;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: calc(14px + 0.4vmin);
      @media screen and (max-width: 500px) {
        flex-direction: column;
      }
    }
    input {
      font-size: calc(12px + 0.5vmin);
      padding: 5px 20px;
      margin: 10px;
      border-radius: 4px;
      border: none;
      &:focus {
        outline: 2px solid pink;
      }
      @media screen and (max-width: 500px) {
        text-align: center;
        width: 150px;
      }
    }
    button {
      font-family: "Roboto", system-ui, -apple-system, BlinkMacSystemFont,
        "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
      -webkit-tap-highlight-color: transparent;
      margin-top: 10px;
      width: fit-content;
      position: relative;
      overflow: hidden;
      transition: background 400ms;
      color: #fff;
      background-color: #751de7;
      font-size: 1rem;
      outline: 0;
      border: 0;
      padding: 10px 20px;
      border-radius: 20px;
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }
  }
  .verses {
    animation: 0.2s fadeIn forwards;
    font-size: calc(1rem + 0.5vmax);
    font-weight: 400;
    padding: 1px 0;
  }
}
span.bubblePop {
  position: absolute;
  top: 0;
  border-radius: 50%;
  transform: scale(0);
  animation: bubblePop 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}
.loader_div {
  display: flex;
  justify-content: center;
}
