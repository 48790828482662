$primary-color: rgb(220, 160, 255);
@import "~loaders.css/src/animations/ball-pulse";

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  transform: scale(1);
}
li {
  list-style: none;
}
